<template>
  <div class="container" ref="mainContainer">
    <v-row>
      <v-col cols="12">
        <v-btn
          class="rounded-pill my-2"
          color="primary"
          v-if="!isNaN(step) && step > 1 && !$cookies.get('contratoEnviado')"
          @click="step--"
          >Atrás</v-btn
        >
        <v-btn
          class="rounded-pill my-2"
          color="primary"
          v-else-if="step != 1"
          @click="
            step = 1;
            $cookies.remove('contratoEnviado');
            todoOK = false;
          "
          >Volver al inicio</v-btn
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-stepper flat v-show="step > 0 && step < 5" :value="step">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1"
              >Datos del suministro</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" step="2"
              >Marca tu cubierta</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 3" step="3"
              >Datos de consumo</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 4" step="4"
              >Datos de contacto</v-stepper-step
            >
            <!-- <v-divider></v-divider>
            <v-stepper-step :complete="step > 3" step="3"
              >Datos de envío</v-stepper-step
            > -->
          </v-stepper-header>
        </v-stepper>

        <v-tabs-items touchless v-model="step">
          <v-tab-item :value="0" key="step0" eager>
            <div class="mx-2">
              <h1 class="text-center font-weigth-bold">
                ¡SOLICITA TU ESTUDIO EN 5 MINUTOS!
              </h1>
              <h2 class="text-center mt-8">¿Cuál es tu perfil?</h2>
              <div
                class="d-flex align-center justify-space-around mt-8 flex-wrap"
                style="gap: 20px"
              >
                <v-card
                  class="item-selection align-center pa-4 ma-1"
                  style="height: 175px; width: 175px; aspect-ratio: 1"
                  @click.stop="
                    datos.listaAC = 11;
                    step = 1;
                  "
                >
                  <h4 class="primary--text">Vivienda habitual</h4>
                  <v-icon large>mdi-home</v-icon>
                </v-card>

                <v-card
                  class="item-selection align-center pa-4 ma-1"
                  style="height: 175px; width: 175px; aspect-ratio: 1"
                  @click.stop="
                    datos.listaAC = 13;
                    step = 1;
                  "
                >
                  <h4 class="primary--text">Comunidad de vecinos</h4>
                  <v-icon large>mdi-home-group</v-icon>
                </v-card>

                <v-card
                  class="item-selection align-center pa-4 ma-1"
                  style="height: 175px; width: 175px; aspect-ratio: 1"
                  @click.stop="
                    datos.listaAC = 12;
                    step = 1;
                  "
                >
                  <h4 class="primary--text">Negocio o Empresa</h4>
                  <v-icon large>mdi-domain</v-icon>
                </v-card>
              </div>
            </div>
          </v-tab-item>

          <v-tab-item :value="1" key="step1" eager>
            <div class="mx-2">
              <h2 class="text-center">Por favor indíca tu dirección</h2>
              <h5 class="text-center">
                Así podremos realizar el estudio más adaptado a tus necesidades
              </h5>
              <br />
              <v-form @submit.prevent autocomplete="off" ref="form1">
                <v-row class="row mt-4">
                  <v-col cols="12" sm="6" offset-sm="3">
                    <v-text-field
                      outlined
                      v-model="datos.cups_principal.direccion"
                      hide-details="auto"
                      :rules="[rules.req]"
                      placeholder="Dirección suministro"
                    >
                      <!-- <template v-slot:append-outer>
                        <v-btn
                          class="mt-n2"
                          color="primary"
                          @click="buscarDireccion"
                          :loading="isLoading"
                        >
                          <v-icon>mdi-google-maps</v-icon>
                        </v-btn>
                      </template> -->
                    </v-text-field>
                  </v-col>
                  <div class="mt-4 col-12 text-center">
                    <v-btn
                      large
                      color="primary"
                      class="rounded-pill"
                      @click="$refs.form1.validate() ? step++ : null"
                      type="button"
                      >Continuar</v-btn
                    >
                  </div>
                </v-row>
              </v-form>
            </div>
          </v-tab-item>

          <v-tab-item :value="2" key="step2" eager>
            <v-form @submit.prevent ref="form2">
              <v-row>
                <v-col cols="12" md="8">
                  <tarjeta-datos header titulo="Mapa" icon="map">
                    <div style="min-height: 500px; display: grid">
                      <Map
                        ref="map"
                        :center="{
                          lat: Number(datos.latitud),
                          lng: Number(datos.longitud),
                        }"
                        :zoom="20"
                        v-model="datos.cubiertas"
                        :marker="datos.freezePosition"
                        @changePosition="
                          ({ latitud, longitud }) => {
                            datos.latitud = latitud;
                            datos.longitud = longitud;
                            datos.freezePosition = true;
                          }
                        "
                      />
                    </div>
                  </tarjeta-datos>
                </v-col>
                <v-col cols="12" md="4">
                  <tarjeta-datos
                    header
                    titulo="Cubiertas seleccionadas"
                    icon="google-maps"
                  >
                    <div class="d-flex flex-column ma-4" style="gap: 16px">
                      <v-card
                        width="100%"
                        v-for="c in cubiertasMarcadas"
                        :key="c.id"
                        class="py-3"
                      >
                        <v-row no-gutters>
                          <v-col
                            cols="auto"
                            class="d-flex justify-center align-center"
                          >
                            <v-avatar
                              @click.stop="
                                datos.latitud = c.center.lat;
                                datos.longitud = c.center.lng;
                              "
                              :color="c.color"
                              size="30"
                              class="mx-2"
                              style="cursor: pointer"
                            />
                          </v-col>
                          <v-col class="d-flex flex-column mx-2">
                            <v-text-field
                              v-model="c.nombre"
                              single-line
                              append-icon="mdi-feather"
                              dense
                              hide-details="auto"
                              :rules="[rules.req, rules.nombreCubierta]"
                              :hint="c.area"
                              persistent-hint
                            />
                          </v-col>
                          <v-col
                            cols="auto"
                            class="d-flex justify-center align-center mr-2"
                          >
                            <v-btn
                              color="error"
                              icon
                              @click.stop="removePolygon(c)"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-alert
                        v-if="cubiertasMarcadas.length == 0"
                        type="info"
                        text
                        >Es importante que marques tu cubierta para que
                        realicemos tu estudio</v-alert
                      >
                    </div>
                  </tarjeta-datos>
                </v-col>
                <v-col cols="12">
                  <div class="mt-4 col-12 text-center">
                    <v-btn
                      large
                      color="primary"
                      class="rounded-pill"
                      @click="$refs.form2.validate() ? step++ : null"
                      :disabled="cubiertasMarcadas.length == 0"
                      type="button"
                    >
                      Continuar
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>

          <v-tab-item :value="3" key="step3" eager>
            <div class="mx-2">
              <h2 class="text-center">Por favor indíca tu CUPS</h2>
              <h5 class="text-center">
                Así podremos calcular tu ahorro según lo que consumes
              </h5>
              <br />
              <v-form @submit.prevent autocomplete="off" ref="form3">
                <v-row class="row mt-4">
                  <v-col cols="12" sm="6" offset-sm="3">
                    <v-text-field
                      outlined
                      v-model="datos.cups_principal.cups"
                      hide-details="auto"
                      :rules="[rules.req, rules.cups]"
                      placeholder="Código CUPS"
                    >
                    </v-text-field>
                  </v-col>
                  <div class="mt-4 col-12 text-center">
                    <v-btn
                      large
                      color="primary"
                      class="rounded-pill"
                      @click="$refs.form3.validate() ? step++ : null"
                      type="button"
                      >Continuar</v-btn
                    >
                  </div>
                </v-row>
              </v-form>
            </div>
          </v-tab-item>

          <v-tab-item :value="4" key="step4" eager>
            <div class="mx-2">
              <h2 class="text-center">Datos de contacto</h2>
              <br />
              <v-form @submit.prevent ref="formContacto" autocomplete="off">
                <v-row class="mt-4">
                  <v-col cols="12" md="6" offset-md="3">
                    <v-text-field
                      label="Nombre completo"
                      outlined
                      hide-details="auto"
                      :rules="[rules.req]"
                      v-model="datos.cliente.nombre"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" offset-md="3">
                    <v-text-field
                      label="E-mail"
                      outlined
                      hide-details="auto"
                      :rules="[rules.req, rules.email]"
                      v-model="datos.cliente.contactos[0].valor"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Teléfono"
                      outlined
                      hide-details="auto"
                      :rules="[rules.req, rules.phone]"
                      v-model="datos.cliente.contactos[1].valor"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" offset-md="3" class="mt-3">
                    <v-textarea
                      label="Observaciones (opcional)"
                      outlined
                      hide-details="auto"
                      v-model="datos.descripcionProyecto"
                    ></v-textarea>
                  </v-col>
                  <div class="mt-4 col-12 text-center">
                    <v-btn
                      large
                      color="primary"
                      class="rounded-pill"
                      @click.stop="
                        $refs.formContacto.validate() ? enviarEstudio() : null
                      "
                      type="button"
                      :loading="enviando"
                      >Enviar estudio</v-btn
                    >
                  </div>
                </v-row>
              </v-form>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-dialog v-model="error" persistent max-width="600px" width="100%">
      <v-card class="pa-4">
        <div class="d-flex align-center mb-4">
          <v-icon left color="error" x-large>mdi-alert-circle</v-icon>
          <h3 class="error--text">Ha ocurrido un error</h3>
        </div>
        <h4>
          Ha ocurrido un error inesperado, vuelve a intentarlo más tarde o ponte
          en contacto con nosotros
        </h4>
        <div class="d-flex">
          <v-btn
            text
            color="primary"
            :href="`tel:${telefono.replaceAll(/ /g, '')}`"
          >
            <v-icon left>mdi-phone</v-icon>
            {{ telefono }}
          </v-btn>
          <v-btn text color="primary" :href="`mailto:${email}`">
            <v-icon left>mdi-email</v-icon>
            {{ email }}
          </v-btn>
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click.stop="error = false"
            v-text="'OK'"
          />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  parseDate,
  obtenerNombreTarifaML,
  calcularConsumoAnual,
  downloadFile
} from "@/utils/index.js";
import {
  cups,
  req,
  spainIdType,
  ValidateSpanishID,
  email,
  iban,
  phone,
  isNumber,
} from "@/utils/validations.js";
import axios from "axios";

export default {
  components: {
    tuTarifa: () => import("@/components/tuTarifa.vue"),
    Map: () => import("@/components/Map.vue"),
    TodoOk: () => import("@/components/TodoOk.vue"),
    TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
    AuxInput: () => import("@/components/AuxInput.vue"),
    FormularioContacto: () => import("@/components/FormularioContacto.vue"),
    DragAndDrop: () => import("@/components/DragAndDrop.vue"),
  },
  mounted() {
    this.finishedLoading = true;

    const resizeObserver = new ResizeObserver((entries) => {
      let message = { height: entries[0].target.clientHeight };
      window.top.postMessage(message, "*");
    });

    resizeObserver.observe(this.$refs.mainContainer);
  },
  props: {
    empresa: String,
    nombreEmpresa: String,
    telefono: String,
    email: String,
  },
  data() {
    return {
      rounded:
        this.$route.query.rounded && JSON.parse(this.$route.query.rounded)
          ? "rounded-pill"
          : "",
      finishedLoading: false,
      nombreAgente: "FormularioWeb",
      // step: this.$cookies.get("contratoEnviado") ? 4 : 0,
      step: 1,
      facturaEnMano: false,
      infoCups: false,
      error: false,
      check: [null, null],
      enviando: false,
      datos: {
        cliente: {
          contactos: [
            { tipo : 'E', valor: null },
            { tipo : 'T', valor: null }
          ],
        },
        cups_principal: {},
        cubiertas: [],
        potencias: {},
        latitud: 0,
        longitud: 0,
        autoestudio: {
          tipo: "estudio_express",
          calcular_financiacion: true,
          curva: "SERVICIO_GENERAL",
          estructura: "COPLANAR",
          superficie_disponible: null,
          simulacion_vecinos: null,
        },
        tipo:
          this.$route.query.tipo &&
          ["Estudio autoconsumo estándar", "Estudio autoconsumo colectivo"]
            .map((t) => btoa(t))
            .includes(this.$route.query.tipo)
            ? atob(this.$route.query.tipo)
            : "Estudio autoconsumo estándar",
      },
      ciudadesPosibles: {
        suministro: [],
        direccion: [],
        datosEnvio: [],
      },
      callesPosibles: {
        suministro: [],
        direccion: [],
        datosEnvio: [],
      },
      debouncer: {
        suministro: null,
        direccion: null,
      },

      documentacion: [],

      idEmpresa: 1,
      idUsuario: 1,

      rules: {
        req,
        ValidateSpanishID,
        cups: cups,
        email,
        iban,
        isNumber,
        phone,
        nombreCubierta: this.nombreCubierta,
      },

      cupsSinDatos: false,

      enviadoEfor: false,

      isLoading: false,
      busqueda: {
        ciudad: {
          suminsitro: "",
          direccion: "",
        },
      },
      todoOK: false,
      subiendoContrato: false,
    };
  },
  computed: {
    esEmpresa() {
      return (
        this.datos.cups_principal.direccion.identificador != null &&
        spainIdType(this.datos.cups_principal.direccion.identificador) == "cif"
      );
    },
    validCUPS() {
      return cups(this.datos?.cups_principal?.cups);
    },
    cubiertasMarcadas() {
      if (this.datos.cubiertas == null || this.datos.cubiertas.length == 0)
        return [];
      const cubiertasMarcadas = this.datos.cubiertas.filter(
        (c) => c.polygon.visible
      );
      if (cubiertasMarcadas.length > 0 && !this.datos.freezePosition) {
        const coords = cubiertasMarcadas[0].polygon
          .getPath()
          .getArray()
          .map((p) => [p.lat(), p.lng()]);

        var x = coords.map((xy) => xy[0]);
        var y = coords.map((xy) => xy[1]);
        var cx = (Math.min(...x) + Math.max(...x)) / 2;
        var cy = (Math.min(...y) + Math.max(...y)) / 2;
        this.datos.latitud = cx;
        this.datos.longitud = cy;
      }
      return cubiertasMarcadas;
    },
    periodos() {
      if (this.datos.suministro.tarifa == null) return 0;
      if (this.datos.suministro.tarifa == "2.0TD ML") return 3;
      if (this.datos.suministro.tarifa != "2.0TD ML") return 6;
    },
  },
  methods: {
    parseDate,
    nombreCubierta(v) {
      const nombres = this.cubiertasMarcadas.map((c) => c.nombre);
      return (
        nombres.length == [...new Set(nombres)].length ||
        "El nombre no se puede repetir entre cubiertas"
      );
    },
    removePolygon(pol) {
      pol.polygon.visible = false;
      this.datos.cubiertas.splice(
        this.datos.cubiertas.findIndex((item) => item.id == pol.id),
        1,
        pol
      );
    },
    async buscarDireccion() {
      this.isLoading = true;
      try {
        if (this.datos.freezePosition) {
          this.isLoading = false;
          return;
        }
        const { lat, lng } = await this.$refs.map.buscarDireccion(
          this.datos.cups_principal.direccion
        );
        // const { data: coords } = await axios({
        // 	url: `https://maps.googleapis.com/maps/api/geocode/json`,
        // 	params: {
        // 		key: "AIzaSyDApxfUsXUHDJ7fYgETkz_nRImNgwjAB2o",
        // 		address: encodeURIComponent(this.datos.suministro.direccion),
        // 	}
        // }
        // );
        this.datos.latitud = lat;
        this.datos.longitud = lng;
      } catch (err) {
        console.error(err);
      }
      this.isLoading = false;
    },
    estudioBuilder(vm) {
      return new Object({
        datos: {
          ...vm.datos,
          nombreProyecto: vm.datos.cliente.nombre,
          datos: null,
          cubiertas: vm.datos.cubiertas
            .filter((c) => c.polygon.visible)
            .map((c) => ({ ...c, polygon: c.polygon.getPath().getArray() })),
        },

        guardarBorrador: function () {
          return new Promise(async (resolve, reject) => {
            axios({
              method: "POST",
              url: `${process.env.VUE_APP_API_URL}/estudios/estudio_web`,
              data: {
                ...this.datos,
                idEstudio: null,
                estado: "Pendiente Revisión",
              },
            })
              .then(({ data }) => resolve(data))
              .catch((err) => {
                console.error(err);
                reject(err);
              });
          });
        },
      });
    },
    async enviarEstudio(borrador) {
      this.enviando = true;
      let estudioPotencial = new this.estudioBuilder(this);
      try {
        const propuesta = await estudioPotencial.guardarBorrador();
        this.$root.$emit(
          "snack",
          "Se ha generado tu propuesta"
        );

        this.$store.commit('savePropuesta', propuesta)
        this.$router.push('/propuesta')

        this.enviando = false;
        return;
      } catch (e) {
        console.error(e);
        this.$root.$emit("snack", "Ha ocurrido un error al enviar el estudio");
        this.enviando = false;
      }
    },
  },
  watch: {
    "datos.cups_principal.direccion"(val) {
      if (!val) return;
      clearTimeout(this.debouncer.direccion);
      this.debouncer.direccion = setTimeout(() => {
        //TODO: BUSCAR EN MAPS
        this.buscarDireccion();
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
h1,
h2,
h3 {
  color: var(--v-primary-base);
  font-weight: bold;
  font-size: calc(1.325rem + 0.9vw);
  font-family: "silka_bold";
}
h5,
.h5 {
  font-size: 1.25rem;
  font-weight: 500;
}
a.nav-link,
a {
  padding: 0.5rem 0.5rem !important;
  text-decoration: none !important;
  font-weight: bold;
  position: relative;
}

.text-final {
  color: var(--v-primary-base);
  font-weight: bold;
  font-size: 1.5rem;
  font-family: "silka_bold" !important;
  & + a {
    font-weight: bold;
    font-size: 1.5rem;
    text-decoration: none;
    color: var(--v-secondary-base) !important;
    font-family: "silka_bold" !important;
  }
}

.item-selection {
  display: grid !important;
  grid-template-rows: 1fr 0fr;
  text-align: center;
}

.theme--light.v-tabs-items {
  background-color: transparent !important;
}
</style>
